.container {
    padding: 16px;
}

.section {
    padding-top: 2em;
    padding-bottom: 2em;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
}

.grid-container {
    display: grid;
}

.flex-item {
    width: 5em;
}

.green-background {
    color: white;
    background-image: linear-gradient(#00B562, #01A75A)
}

/* header */
.header.grid-container {
    grid-template-columns: minmax(200px, 500px);
    grid-template-rows: repeat(auto-fill, minmax(100px, max-content));
    justify-content: center;
    padding-top: 2em;
    padding-bottom: 2em;
}

.header-text {
    text-align: center;
    margin-bottom: 20px;
}

.header-text h1 {
    margin-bottom: 10px;
    font-size: 55px;
}

.header-text p {
    margin-top: 0;
    font-size: 20px;
    margin: 0px 10px 0 10px;
}

#icon {
    height: 150px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.companies {
    margin-top: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fill, minmax(100px, max-content));
    grid-row-gap: 50px;
    justify-items: center;
    align-items: end;
}

.companies-container {
    max-width: 70%;
    margin: auto auto auto auto;
    padding-top: 1em;
    padding-bottom: 3em;
}

hr.full-hr {
    border-color: #f7f7f7;
}

.logo {
    max-height: 200px;
    max-width: 70%;
}

/* portfolio */

.portfolio.grid-container {
    grid-template-rows: 3em, 3em repeat(auto-fill, minmax(100px, max-content));
    grid-column-gap: 10%;
    grid-row-gap: 20px;
    align-items: baseline;
    text-align: center;
    max-width: 80%;
    justify-items: center;
    margin: auto auto 3em auto;
}

.portfolio h2,
.portfolio p {
    margin-top: 0.5em;
    margin-bottom: 0;
}

button:hover {
    cursor: pointer;
}

.contact-bottom .contact-button {
    background-color: #01A75A;
    color: white;
    font-family: inherit;
}

.contact-bottom .contact-button:hover {
    background-color: #018648;
    color: white;
}

/* footer */

.footer {
    background-color: #3AB600;
    padding-top: 4em;
    padding-bottom: 4em;
}

.footer a:not(:last-child) {
    margin-right: 10px;
}

.footer a {
    text-decoration: none;
    color: white;
}

.footer div {
    font-weight: 0 !important;
}

.footer :not(:last-child) {
    margin-bottom: 4px;
}

.footer :first-child {
    font-size: 1.5em;
    font-family: 'AvenirNext-Medium';
}

.footer .contact-button {
    max-width: 80%;
    font-size: 1.1em;
    font-family: inherit;
}

.copyright-text {
    font-size: 0.7em;
    margin-top: 5em;
}

/* contact me */

.contact-me .about-text {
    margin-bottom: 2em;
}

.partial-hr {
    width: 80%;
    color: rgba(131, 130, 130, 0.81);
}

.contact-me hr {
    width: 80%;
    color: rgba(131, 130, 130, 0.81);
}

.contact-button {
    margin-left: auto;
    margin-right: auto;
    height: 45px;
    width: 210px;
    border: 2px solid white;
    background: none;
    border-radius: 8px;
    color: white;
    font-size: 1.2em;
    font-family: inherit;
    font-weight: 550;
}

.contact-button:hover {
    color: darkslategray;
    background-color: white;
    transition: background 0.1s;
}

.contact-me {
    text-align: center;
    padding-bottom: 4em;
    padding-top: 4em;
}

.contact-me .content-container {
    max-width: 600px;
}

.contact-me:last-child {
    margin-bottom: 5em;
}

#contact-modal h2 {
    margin-top: 0;
}

.modal button {
    margin-left: auto;
    margin-right: auto;
    height: 45px;
    width: 210px;
    border: 2px solid white;
    border-radius: 8px;
    background-image: linear-gradient(#00B562, #01A75A);
    color: white;
    font-family: inherit;
    font-size: 1.1em;
    font-weight: 600;
}

.modal button:hover {
    background-color: white;
    transition: background 0.1s;
}

.modal input,
.modal textarea {
    border: 1px solid #cecece;
    border-radius: 3px;
    min-height: 25px;
    font-size: inherit;
    display: block;
}

.modal input:not(:last-child) {
    margin-bottom: 15px;
    width: 90%;
}

.modal textarea {
    resize: vertical;
    margin-bottom: 16px;
    min-height: 200px;
    font-size: inherit;
    width: 100%;
    border: 1px solid #cecece;
}

.close {
    float: right;
    border: none;
    background: none;
    font-size: 28px;
}

/* Hide mobile only on larger screen widths */

@media screen and (min-width: 768px) {
    .mobile-only {
        display: none;
    }

    .header-text h1 {
        margin-bottom: 10px;
        font-size: 50px;
        margin-top: 20px;
    }

    .header.grid-container {
        padding-top: 5em;
        padding-bottom: 5em;
    }

    .portfolio-card {
        height: 299px;
        width: 428px;
        background-size: cover;
        justify-self: center;
    }

    .portfolio-card:last-child {
        margin-bottom: 20px;
    }

    .portfolio.grid-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .portfolio-card:hover {
        transform: scale(1.1);
        -webkit-transition: -webkit-transform 0.3jjjjjjjjjjjjjjs ease;
        -moz-transition: -moz-transform 0.3s ease;
        transition: transform 0.3s ease;
    }

    .contact-me {
        text-align: center;
        justify-content: center;
    }

    .contact-me .about-text {
        max-width: 50%;
    }

    #icon {
        height: 200px;
        width: 200px;
    }



}

@media screen and (max-width: 767px) {
    .header-text h1 {
        margin-bottom: 10px;
        font-size: 12vw;
        margin-top: 20px;
    }

    .portfolio-card {
        max-height: 229px;
        max-width: 90%;
    }

    .portfolio-card:last-child {
        margin-bottom: 20px;
    }

    .header-container {
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .portfolio.grid-container {
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    }
}

@media screen and (max-width: 330px) {
    .portfolio.grid-container {
        justify-items: start;
    }
}

body {
    margin: 0;
    font-family: "Avenir Next";
    color: #242424;
}

.text-center {
    text-align: center;
}